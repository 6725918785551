.featured__container{
  position: relative;
  margin-bottom: 40px;
}
.featured__title{
  margin-bottom: 23px;
  h2{
    margin: 0;
    font-size: $featured-title-size;
    font-weight: $featured-title-weight;
    @media ($mobile) {
      font-size: 47px;
      line-height: 39px;
      span{
        display: block;
      }
    }
  }
}

.featured__products{
  @include prefix('transform', translateY(-50px));
  margin-bottom: 60px;
  padding: 0 50px;
  @media ($mobile) {
    @include prefix('transform', translateY(-10px));
    margin-top: 35px;
    margin-bottom: 0px;
  }
  @include br($tablet){
    margin-bottom: 10px;
  }
  .slick-list{
    padding-top: 50px;
    @media ($mobile) {
      padding-top: 0;
    }
  }
  .featured__item{
    padding: 0 5px;
    &.medium{
      a::after{
        background-size: 80% 70%;
      }
    }
    &.small{
      a::after{
        background-size: 60% 50%;
      }
    }
    &.none{
      a::after{
        opacity: 0;
      }
    }
    a{
      display: block;
      position: relative;
      text-decoration: none;
      &:hover{
        img{
          @media ($not-mobile) {
            @include prefix('transform', translateY(-50px));
          }
        }
        .title{
          opacity: 1;
        }
      }
      img{
        width: 100%;
        margin-bottom: 13PX;
        @include transition('transform');
      }
      &::after{
        content: '';
        position: absolute;
        bottom: -2%;
        left: 0%;
        width: 100%;
        height: 130px;
        z-index: -1;
        background-image: $cup-shadow-img;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @media ($mobile) {
          opacity: 0;
        }
      }
      .title{
        font-weight: bold;
        font-size: 20px;
        color: $body-color;
        opacity: 0;
        display: block;
        padding-top: 5px;
        text-align: center;
        @include transition('opacity');
        @media ($mobile) {
          opacity: 1;
        }
        @include br($tablet){
          opacity: 1;
        }
      }
    }
  }
  .slick-arrow{
    @include prefix('transform', translateY(-50%));
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    content: '';
    color: $trans;
    overflow: hidden;
    background-position: center;
    background-size: 100% 100%;
    background-color: $trans;
    cursor: pointer;
    @media ($mobile) {
      top: 10%;
    }
  }
  .featured-prev-arrow{
    @include prefix('transform', translateY(-50%) rotate(180deg));
    background-image: $featured-nav;
    left: 0;
  }
  .featured-next-arrow{
    background-image: $featured-nav;
    right: 0;
  }
}

.featured__background{
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 223px;
  top: 80px;
  z-index: -1;
  @media ($mobile) {
    top: 25px;
    height: 275px;
  }
}