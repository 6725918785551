.hero__container{
  background-position: center;
  background-size: cover;
  position: relative;
}

.hero__content{
  padding: 50px 0;
  @media ($mobile) {
    padding: 160px 0 65px;
  }
}

.posts-hero-container{
  @media ($mobile) {
    padding: 0;
  }
}

.hero__content-title{
  h2{
    margin: 0;
    font-family: $selima;
    font-size: $hero-title-size;
    font-weight: $hero-title-weight;
    color: $hero-title-color;
    line-height: 110px;
    span{
      font-family: $selima;
    }
    &:first-of-type{
      margin-bottom: 10px;
    }
    @media ($mobile) {
      font-size: 60px;
      line-height: 52px;
    }
  }
}

// .hero__content-description{
//   color: $hero-desc-color;
//   p{
//     font-size: $hero-desc-size;
//     font-weight: $hero-desc-weight;
//     color: $hero-desc-color;
//   }
// }

.hero__arrow{
  background-image: $hero-arrow-img;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  content: '';
  width: 16px;
  height: 45px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  margin: 0 auto;
}

.hero__video{
  margin: 0 20px;
}

.hero__video-button{
  width: 55px;
  height: 55px;
  display: inline-block;
  background-image: $hero-play-img;
  background-position: center;
  background-size: 100% 100%;
  @media ($mobile) {
    width: 45px;
    height: 45px;
    @include prefix(transform, translateY(10px));
  }
}

/* hero slide on blog page */
.hero-slide{
  background-size: cover;
  background-position: center;
  height: 530px;
  max-height: 530px;
  position: relative;
  @include breakpoint(992px, 1199px){
    height: 450px;
  }
  @include breakpoint(501px, 991px){
    height: 370px;
  }
  @include breakpoint(0, 500px){
    height: 300px;
  }
  > .container{
    max-width: 970px;
    
    // margin-top: 120px;
    position: relative;
    z-index: 1;
    top: 50%;
    @include prefix(transform, translateY(-50%));
    @include breakpoint(0, 1199px){
      top: 50%;
      @include prefix(transform, translateY(-50%));
    }
    
  }
}
.hero-slide__content{
  .slide-content__category{
    color: red;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    @media ($mobile) {
      font-size: 18px;
    }
    a{
      &:hover{
        text-decoration: none;
      }
    }
  }

  .slide-content__title{
    color: $white;
    margin-top: 0;
    font-weight: bold;
    font-size: 50px;
    line-height: 47px;
    text-transform: uppercase;
    @media ($mobile) {
      font-size: 35px;
      line-height:34px;
      text-transform: uppercase;
    }
    a{
      color: $white;
      text-decoration: none;
    }
  }

  .slide-content__excerpt{
    font-size: 18px;
    color: $white;
    @media ($mobile) {
      display: none;
    }
  }

  .slide-content__tags{
    color: $white;
    font-size: 16px;
    @media ($mobile) {
      font-size: 16px;
    }
    a{
      color: $white;
      font-weight: bold;
      text-transform: capitalize;
      &:hover,&:focus{
        text-decoration: none;
      }
    }
  }
}

.hero-slide-cover{
  background-color: rgba(0,0,0,.06);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-slide-center{
  position: absolute;
  height: 100%;
  width: 50%;
  max-width: 600px;
  margin-left: 100px;
  @media ($mobile) {
    margin-left: 20px;
    width: 270px;
  }
  .hero-slide__content{
    @include prefix(transform, translateY(-50%));
    position: absolute;
    top: 50%;
    z-index: 1;
  }
}

.hero-slideshow__container{
  .slick-arrow{
    @include prefix(transform, translateY(-50%));
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 1;
    background-image: url($img + 'slider-arrow.svg');
    width: 28px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    overflow: hidden;
    color: transparent;
    @media ($mobile) {
      left: 8px;
      width: 18px;
      height: 15px;
    }
    &.hero-next-arrow{
      @include prefix(transform, translateY(-50%) rotate(180deg));
      left: auto;
      right: 10%;
      @media ($mobile) {
        right: 8px;
      }
    }
  }
  .slick-dots{
    bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    li{
      display: inline-block;
      margin: 6px;
      &.slick-active{
        button{
          background-color: $white;
        }
      }
      button{
        @include radius(50%);
        height: 12px;
        width: 12px;
        -webkit-appearance: none;
        padding: 0;
        color: transparent;
        background-color: transparent;
        border: 2px solid $white;
      }
    }
  }
}

.priority-post__container{
  margin-bottom: 30px;
}
.priority-post-wrapper{
  text-align: center;
  position: relative;
  background-color: $white;
  width: 943px;
  max-width: 100%;
  margin: -120px auto 0;
  .priority-post{
    display: inline-block;
    width: 19%;
    vertical-align: top;
    padding: 30px 10px 30px;
    a{
      display: block;
      text-decoration: none !important;
      .img{
        display: block;
        width: 100%;
        height: 105px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 10px;
      }
      span{
        font-size: 13px;
        color: #343434;
      }
    }
  }
}

@include breakpoint(992px, 1199px){
  .priority-post-wrapper{
    margin: 0 auto;
  }
}

@include breakpoint(0, 991px){
  .priority-post-wrapper{
    margin: 0 auto;
  }
}
@include breakpoint(0, 767px){
  .scroll-area{
    overflow-x: auto;
  }
  .priority-post-wrapper{
    overflow-x: auto;
    max-width: 767px;
    text-align: left;
    margin: 0;
    .priority-post{
      width: 145px;
    }
  }
}