.sidebar-area{
  &.affix{
    position: fixed !important;
    top: 80px;
  }
}

@media ($mobile) {
  .sidebar-area{
    &.affix{
      position: relative !important;
      top: auto;
      max-width: 100% !important;
    }
  }
}