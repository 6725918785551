header{
  &.banner{
    @include transition('background-color');
    background-color: $trans;
    // position: fixed;
    // left: 0;
    // top: 0;
    // left: 0;
    width: 100%;
    z-index: 998;
    padding: 0;
    height: 70px;
    @include breakpoint(0, 991px){
      height: auto;
    }

    > .container-fluid{
      padding: {
        left: 25px;
        right: 25px;
      }
    }
  }
}

.header-hover{
  > header.banner{
    background-color: $white;
  }
}

.nav-primary,.brand{
  // display: inline-block;
  float: left;
}

.logo-white{
  display: none !important;
}
.logo-dark{
  display: inline-block;
}

.brand{
  padding: 12px 0;
  margin-right: 5px;
  text-align: center;
  img{
    display: block;
    width: 108px;
    max-width: 100%;
  }
}
header.banner{
  .brand{
    width: 10%;
  }
  .nav-primary{
    width: 78%;
    ul{
      text-align: center;
    }
  }
  .right-menu{
    width: 11%;
    max-width: 130px;
    text-align: right;
  }
}


.span-fb-like{
  margin-left: 50px;
  -moz-transform: translateY(5px);
  -webkit-transform: translateY(5px);
  -o-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
  .fb-like, .fb-share-button{
    > span{
      vertical-align: top !important;
    }
  }
  iframe{
    -moz-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -o-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
  }
}

.woo-menu{
  right: 10px;
  a{
    @include radius(50%);
    line-height: 19px;
    width: 24px;
    height: 24px;
    background-color: $red;
    padding: 2px 7px;
    span{
      color: $white;
    }
  }
}

header.mobile{
  @include transition;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 999;
  background-color: $white;
}
.mobile-header{
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  > .col-xs-12{
    text-align: center;
  }
  .burger-menu,
  .woo-menu{
    @include prefix(transform, translateY(-50%));
    position: absolute;
    z-index: 1;
    top: 50%;
    a{
      background-color: $trans;
      display: block;
      padding: 0;
      svg{
        width: 21px;
        path{
          fill: #312c2d;
        }
      }
    }
  }
  .brand{
    position: relative;
    display: inline-block;
    text-align: center;
    @include breakpoint(0, 991px){
      float: none;
      margin: 0 auto;
    }
  }
}

.m-ico-dark{
  display: none !important;
}
.m-ico-white{
  display: inline-block;
}

.menu-active{
  .m-ico-dark{
    display: inline-block !important;
  }
  .m-ico-white{
    display: none;
  }
  .mobile-header{
    .woo-menu{
      a{
        svg{
          path{
            fill: $white;
          }
        }
      }
    }
  }
}

.burger-menu{
  height: 21px;
  width: 27px;
  cursor: pointer;
  span{
    @include transition;
    background-color: #312c2d;
    height: 3px;
    width: 100%;
    position: relative;
    display: block;
    margin: 3px 0;
  }
}

.mobile-inline-menu{
  .inline-menu-wrap{
    overflow-x: auto;
  }
  .nav{
    white-space: nowrap;
    li{
      display: inline-block;
      a{
        font-size: 14px;
        color: #312d2e;
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        padding: 10px;
      }
    }
  }
}
