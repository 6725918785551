.services__container{
  .service-item{
    position: relative;
    margin-bottom: 40px;
    margin-top: 40px;
    @media ($mobile) {
      margin-top: 0;
      margin-bottom: 20px;
    }
    img{
      max-width: 100%;
      @media ($mobile) {
        width: 100%;
      }
    }
  }
  .services__title{
    h2{
      margin: 0 0 18px 0;
      font-size: $column-title-size;
      font-weight: $column-title-weight;
      line-height: 64px;
      @media ($mobile) {
        margin-top: 20px;
        font-size: 35px;
        line-height: 34px;
      }
    }
  }
  .services__description{
    font-size: $column-desc-size;
    font-weight: $column-desc-weight;
    p{
      font-size: $column-desc-size;
      font-weight: $column-desc-weight;
    }
  }
  .service-text-wrap{
    @include prefix( transform, translateY(-50%));
    position: absolute;
    padding: 0 15px;
    width: 45%;
    z-index: 1;
    top: 50%;
    @media ($mobile) {
      @include prefix( transform, translateY(0));
      position: relative;
      top: 0;
      width: 100%;
    }
  }
}

.services__image-left{
  .service-text-wrap{
    right: 0;
  }
}
.services__image-right{

}