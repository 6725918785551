.newsletter__container{
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  @media ($mobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page-template-template-shop{
  .newsletter__container{
    background-color: #eeeeee;
    padding-top: 20px;
  }
}
.newsletter__title{
  margin-bottom: 33px;
  @media ($mobile) {
    margin-bottom: 20px;
  }
  h2{
    font-size: $newsletter-title-size;
    font-weight: $newsletter-title-weight;
    @media ($mobile) {
      font-size: 20px;
      margin-top: 0;
    }
    small{
      font-size: $newsletter-subtitle-size;
      font-weight: $newsletter-subtitle-weight;
      @media ($mobile) {
        display: block;
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}

.newsletter__shortcode{
  &.focus{
    form{
      border-color: #9f9f9f;
    }
  }
  form{
    @include transition;
    border: 2px solid #e1e1e1;
    color: $body-color;
    position: relative;
    input{
      display: inline-block;
    }
    input[type="email"]{
      color: $body-color;
      font-size: 18px;
      height: 45px;
      padding: 0 20px;
      width: 100%;
      border: 0px;
      &::-webkit-input-placeholder { color: $body-color; opacity: 1; }
      &::-moz-placeholder { color: $body-color; opacity: 1; } /* firefox 19+ */
      &:-ms-input-placeholder { color: $body-color; opacity: 1; } /* ie */
      &:-moz-placeholder { color: $body-color; opacity: 1; }
    }
    input[type="submit"]{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: $trans;
      border: 0px;
      font-size: 26px;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 15px;
      z-index: 1;
    }
  }
}