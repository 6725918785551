.category-row{
  position: relative;
  overflow: hidden;
  z-index: 0;
  &.fixed{
    .shop-row-img{
      position: fixed;
    }
    .sidebar-wrapper{
      position: fixed;
      &.dark{
        h2, p{
          color: $body-color;
        }
      }
      &.trans{
        opacity: 0;
      }
    }
    @media ($mobile) {
      .shop-row-img{
        position: relative;
      }
      .sidebar-wrapper{
        position: absolute;
        &.dark{
          h2, p{
            color: #fff;
          }
        }
        &.trans{
          opacity: 1;
        }
      }
    }
  }
}
.shop-row-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: -1;
  @media ($mobile) {
    height: 250px;
    position: relative;
  }
  .shop-row-img-cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 1;
  }
  .img{
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
.sidebar-wrapper{
  @include prefix(box-sizing, content-box);
  @include transition;
  padding: 0 15px;
  height: 170px;
  left: 0;
  top: 230px;
  max-width: 200px;
  position: absolute;
  z-index: 99980;
  opacity: 1;
  margin-left: 89px;
  @media ($mobile) {
    height: initial;
    top: 180px;
  }
  .category-section__title{
    h2{
      @include transition;
      margin-top: 0;
      font-size: 50px;
      line-height: 47px;
      font-family: $global-font;
      color: rgb(253, 253, 253);
      font-weight: bold;
      @media ($mobile) {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
  .category-section__desc{
    @media ($mobile) {
      display: none;
    }
    p{
      @include transition;
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      color: #fff;
      margin: 0;
      height: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      font-size: 16px;
    }
  }
}
.shop-row{
  position: relative;
  z-index: 9999;
  @media ($not-mobile) {
    padding-bottom: 90px;
  }
  .category-bg{
    background-color: #fff;
    width: 100%;
    top: 500px;
    bottom: 0;
    position: absolute;
  }
  .category-section__product{
    @include prefix(transform, translateY(-120px));
    margin-top: 500px;
    @media ($mobile) {
      @include prefix(transform, translateY(0));
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .product-item{
      padding: 0 10px;
      @media ($mobile) {
        padding: 0 5px;
      }
    }

    .product-item__name,
    .product-item__price{
      h3, p{
        font-size: 20px;
        @media ($mobile) {
          font-size: 18px;
        }
      }
    }
    .product-item__name{
      h3{
        margin: 25px 0 5px 0;
        font-weight: bold;
        @media ($mobile) {
          margin: 10px 0 0;
        }
      }
      p{
        margin: 0;
        color: #a1a1a1;
      }
      .product-item__excerpt{
        color: #343434;
        font-size: 18px;
      }
    }
    .product-item__price{
      p{
        color: #ff0000;
      }
    }
  }
}
.shop-filter{
  position: fixed;
  z-index: 1;
  bottom: 20px;
  width: 180px;
  ul{
    @include transition;
    background-color: #fff;
    width: 180px;
    list-style: none;
    padding: 0;
    margin: 0 0 0 15px;
    li{
      &:hover,
      &:focus,
      &:active
      {
        ul{
          opacity: 1;
          z-index: 1;
          height: auto;
        }
      }
      ul{
        opacity: 0;
        z-index: -99;
        height: 0;
        overflow: hidden;
        margin: 0;
      }
      span{
        position: relative;
      }
      a, span{
        display: block;
        font-size: 20px;
        font-family: $global-font;
        color: rgb(161, 161, 161);
        padding: 5px 15px;
        cursor: pointer;
        &:hover, &:focus{
          text-decoration: none;
        }
      }
    }
  }
}