.news__container{
  padding: 40px 0;
  @media ($mobile) {
    padding: 20px 0;
  }
}
.news__title{
  font-size: $news-title-size;
  font-weight: $news-title-weight;
  margin-bottom: 43px;
  @media ($mobile) {
    margin-bottom: 10px;
  }
  h2{
    font-size: $news-title-size;
    font-weight: $news-title-weight;
    margin: 0px;
    @media ($mobile) {
      font-size: 35px;
    }
    .button{
      margin-left: 37px;
    }
  }
}

.news__post{
  > a{
    background-color: #000;
  }
  &:hover{
    .news__post-title h3 a{
      color: lighten($body-color, 20%);
    }
    .news__post-image{
      opacity: 1;
    }
  }
}
.news__post-image{
  @include transition;
  height: 240px;
  width: 100%;
  display: block;
  background-size: cover;
  background-position: center;
  margin-bottom: 30px;
  opacity: .9;
  background-size: cover;
  @media ($mobile) {
    margin-bottom: 10px;
  }
}
.col-sm-6{
  .news__post-image{
    height: 350px;
  }
}
.news__post-category{
  a{
    font-sie: $news-item-category-size;
    font-weight: $news-item-category-weight;
    text-transform: uppercase;
    display: inline-block;
    @media ($mobile) {
      font-size: 14px;
    }
  }
}
.news__post-title{
  h3{
    font-size: $news-item-title-size;
    font-weight: $news-item-title-weight;
    margin: 23px 0 18px;
    @media ($mobile) {
      font-size: 22px;
      margin: 5px 0;
    }
    a{
      @include transition;
      color: $body-color;
      text-decoration: none;
      &:hover{
        color: lighten($body-color, 10%);
      }
    }
  }
}
.news__post-description{
  color: $body-color;
  @media ($mobile) {
    display: none;
  }
  p{
    color: $body-color;
  }
}
.news__post-meta{
  p{
    color: $news-item-meta;
    @media ($mobile) {
      margin-bottom: 30px;
    }
    a{
      display: inline-block;
      color: $news-item-meta;
      text-decoration: none;
    }
  }
}

.editor-pick__container{
  margin-bottom: 0;
  padding: 30px 0;
  background-color: #ebebeb;
}
.editor-pick__title{
  margin-bottom: 40px;
  @media ($mobile) {
    margin-bottom: 10px;
  }
  h2{
    font-size: $news-title-size;
    font-weight: $news-title-weight;
    @media ($mobile) {
      font-size: 35px;
    }
  }
}

.latest-article{
  background-color: red;
  color: $white;
  text-transform: uppercase;
  font-size: 13px;
  position: absolute;
  padding: 5px 10px;
  z-index: 1;
  margin-top: -11px;
  margin-left: -10px;
}

.post-card-wrapper > div,
.editor-pick__container .col-sm-4{
  padding-left: 20px;
  padding-right: 20px;
}
