body{
  &.no-hero{
    padding-top: 80px;
  }
}

.site-wrap{
  position: relative;
  .wrap{
    .main-section{
      padding-top: 70px;
      @media screen and (max-width: 991px){
        padding-top: 116px;
      }
    }
  }
}

.sidemenu{
  margin-bottom: 30px;
}