.nav-primary{
  .nav{
    li{
      display: inline-block;
      // float: left;
      a{
        text-transform: uppercase;
        color: $body-color;
        // color: $white;
        padding: 10px 13px;
        font:{
          size: $nav-menu-size;
          weight: $nav-menu-weight;
        }
        height: 70px;
        vertical-align: middle;
        display: table-cell;
        @include breakpoint(0, 1199px){
          font-size: 12px;
          padding: 10px 7px;
        }
        &:hover, &:focus{
          background-color: $submenu-bg;
          color: $white !important;
        }
      }
    }
    li.menu-item-has-children{
      > a{
        position: relative;
        // &::after{
        //   content: '';
        //   position: absolute;
        //   background-image: url(../images/menu-arrow.png);
        //   background-position: center;
        //   background-repeat: no-repeat;
        //   height: 10px;
        //   width: 10px;
        //   margin:{
        //     top: 5px;
        //     left: 1px;
        //   }
        // }
      }
      &:hover, &:focus{
        > a{
          background-color: $submenu-bg;
          color: $white !important;
        }
        .sub-menu-wrap{
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .sub-menu-wrap{
      @include transition;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      left: 0;
      right: 0;
      padding: 0;
      height: 70px;
      top: 70px;
      overflow: hidden;
      background-color: $submenu-bg;
      .sub-menu{
        padding: 0;
        max-width: 1140px;
        margin: 0 auto;
      }
      li{
        a{
          @include transition;
          color: $white !important;
          opacity: .5;
          padding: 25px 15px;
          display: block;
          max-height: 70px;
          &:hover, &:focus{
            opacity: 1;
            text-decoration: none;
          }
        }
        &:hover, &:focus{
          a{
            opacity: 1;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.right-menu{
  height: 70px;
  padding: 25px 0;
  > * {
    display: inline-block;
    color: $white;
  }
  .divider{
    vertical-align: top;
    &:first-of-type{
      @include br($tablet) {
        display: none;
      }
    }
  }
  .svg-img{
    width: 15px;
    height: 15px;
  }
  .social-menu{
    position: relative;
    @include br($tablet) {
      display: none;
    }
    img{
      width: 12px;
    }
    a{
      vertical-align: middle;
    }
    .follow{
      position: relative;
      cursor: pointer;
      color: rgba(#343434, .6);
    }
    &:hover{
      .dropdown-menu{
        display: block;
      }
    }
    .dropdown-menu{
      padding: 30px;
      left: -115%;
      &::before{
        @include prefix(transform, rotate(45deg));
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #fff;
        top: -5px;
        left: 50%;
        margin-left: -10px;
        border: {
          top: 1px solid #ccc;
          left: 1px solid #ccc;
          right: 1px solid transparent;
          bottom: 1px solid transparent;
        }
      }
      li{
        border-radius: 2px;
        &:last-of-type{
          a{
            margin-bottom: 0;
          }
        }
        a{
          text-align: center;
          padding: 12px;
          margin-bottom: 15px;
          opacity: 1;
          background-color: transparent;
          &:hover{
            opacity: 0.8;
          }
          path{
            fill: #fff;
          }
        }
      }
      .social-fb{
        background-color: #3b5998 !important;
      }
      .social-ig{
        background-color: #3f729b !important;
      }
      .social-tw{
        background-color: #55acee !important;
      }
      .social-yt{
        background-color: #cd201f !important;
      }
    }
  }
  a{
    padding: 0 6px;
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: $white;
    opacity: .6;
    font:{
      size: $nav-menu-size;
      weight: $nav-menu-weight;
    }
    &:hover{
      background-color: $trans;
      opacity: 1;
    }
    svg{
      path{
        fill: #343434;
      }
    }
  }
  
  .account, .search{
    svg{
      @include prefix(transform, translateY(1px));
      width: 13px;
      height: 13px;
      margin-left: 4px;
    }
    span{
      @include breakpoint(0, 1199px){
        display: none;
      }
    }
  }
  .search{
    svg{
      margin-left: 0px;
    }
  }
}

.open-search{
  .search-container{
    visibility: visible;
    opacity: 1;
  }
}

.search-container{
  @include transition;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 19px 0;
  height: 70px;
  background-color: #efefef;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.1);
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.1);
  .col-sm-12{
    > *{
      display: inline-table;
      vertical-align: middle;
    }
  }
  .svg-img{
    width: 26px;
    height: 26px;
    path{
      fill: #333 !important;
    }
  }
  .search-form{
    width: 90%;
    padding: 0px !important;
    label{
      width: 100%;
    }
  }
  .close-search{
    float: right;
    cursor: pointer;
  }
  .search-field{
    @include searchfield;
  }
  @include breakpoint(0, 991px){
    .search-form{
      width: 75%;
      label{
        margin-bottom: 0;
      }
    }
  }
}

#mobile-menu{
  @include transition;
  @include prefix(transform, translateX(-100%));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: #282828;
  z-index: 998;
  padding-top: 70px;
  overflow-y: auto;
  .bg-cover{
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #efefef;
    // background-image: url($img + 'intro.jpg');
    // background-size: cover;
    // background-position: center;
  }
  .nav-primary{
    width: 100%;
    background-color: #efefef;
  }
  ul{
    text-align: center;
  }
  li{
    width: 100%;
    display: block;
    a{
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

.mobile-menu-wrapper{
  .nav-primary{
    padding: 15px 0;
    p{
      padding: 0 33px;
      position: relative;
      color: #cacaca;
      font-size: 12px;
    }
    .nav{
      li{
        text-align: left;
        &.menu-item-has-children.open{
          > a{
            background-color: darken(#efefef, 10%) !important;
            color: #312d2e !important;
          }
          .sub-menu{
            display: block;
          }
        }
        a{
          width: 100%;
          display: block;
          height: auto;
          font-size: 18px;
          padding: 15px 33px !important;
          color: #312d2e;
          text-transform: uppercase;
          text-align: left;
          &:hover, &:focus, &:active{
            background-color: darken(#efefef, 10%);
            color: #312d2e !important;
          }
        }
      }
    }
  }
  .sub-menu{
    @include clearfix;
    display: none;
    padding: 0;
    background-color: darken(#efefef, 5%);
  }
  .menu-item-has-children{
    a{
      &::after{
        margin-left: 10px !important;
        margin-top: 7px !important;
      }
    }
  }
  .social-menu{
    position: relative;
    padding: 15px 33px;
    
    a{
      margin-right: 24px;
      svg{
        width: 28px;
        height: 28px;
        path{
          fill: #525252
        }
      }
    }
  }
}

.menu-separator{
  display: block;
  height: 1px;
  width: 100%;
  position: relative;
  background-color: #d5d5d5;
}
