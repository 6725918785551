// content left
.content-left__container{
  position: relative;
}

.content-left,
.content-right{
  position: relative;
}
.content-left-title__wrap{
}
.content-left__title,
.content-right__title,
.stockist__title{
  position: relative;
  padding-top: 30px;
  margin-bottom: 35px;
  @media ($mobile) {
    padding-top: 10px;
    margin-bottom: 10px;
  }
  h2{
    font-size: 65px;
    font-weight: bold;
    line-height: 58px;
    margin: 0;
    @media ($mobile) {
      font-size: 35px;
      line-height: 37px;
    }
  }
}
.content-left__container,
.content-right__container,
.stockist__container,
.mod-content__container{
  margin-bottom: 60px;
  @media ($mobile) {
    margin-bottom: 10px;
  }

  .pull-right{
    @media ($mobile) {
      float: none !important;
    }
  }
}

.content-right-title__wrap{
}

.stockist-list{
  @extend .clearfix;
  margin: 0;
  padding: 0;
  list-style: none;
  @media ($mobile) {
    margin: 10px 0 20px;
  }
  li{
    display: block;
    float: left;
    width: 25%;
    img{
      max-width: 100%;
    }
  }
}

.content-img{
  position: absolute;
  height: 100% !important;
  @media ($mobile) {
    position: relative;
  }
  &.img-right{
    right: 0;
  }
  img{
    max-height: 100%;
  }
}