// ==========================================================================
// Mixins
// Thanks to @sutterlity
// https://github.com/sutter/POPY-Starter-integration-web/blob/master/scss/tools/_mixins.scss
// ==========================================================================

/**
 * SMACSS Theming
 * @example scss
 * .foobar {
 *   background: #ff3377;
 *   @include theme(beccapurple){
 *     background: #663399;
 *   }
 * }
 * @param {String} $name - theme name
 */

@mixin theme($name) {
  @if $theme == $name {
     @content;
  }
}

/**
 * Buttons
 * This customises your buttons with a different background color and text color.
 * If no text color is specified it will default to white.
 * @param {String} $color-background - background color
 * @param {String} $color-text (#fff) - text color
 * @example scss
 * .foobar { @include btn(#f37) { ... } }
 */

@mixin btn($color-background, $color-text: #fff) {
  background-color: $color-background;
  border-color: darken($color-border, 6%);
  color: $color-text;
}

/**
 * Media Queries
 * Allows you to use inline media queries.
 * @link http://jakearchibald.github.com/sass-ie/
 * @param {String} $breakpoint - breakpoint
 * @param {String} $query (min-width) - query type
 * @param {String} $type (screen) - media type
 * @example scss
 * .foobar { @include mq(20em) { ... } }
 */

@mixin mq($breakpoint, $query: 'min-width', $type: 'screen') { // breakpoint can be a variable
  // if media queries are not supported
  @if $fix-mqs {
    @if $fix-mqs >= $breakpoint { // ...and if the fixed breakpoint is greater than query...
      @content; // ...output the content the user gave us.
    }
  }
  // Otherwise, output it using a regular media query
  @else {
    @media #{$type} and (#{$query}: #{$breakpoint}) { @content; }
  }
 }

/**
 * IE Specific Styles
 * @example scss
 * .foobar {
 *   .lt-ie8 & { @include old-ie { ... } }
 *   .lt-ie9 & { @include old-ie { ... } }
 * }
 */

@mixin old-ie {
  @if $old-ie {
     @content;
  }
}

/**
 * Sass version of Sticky Footer by Ryan Fait
 * @link http://ryanfait.com/sticky-footer/
 * @param {String} $footer_height - height of footer including padding or borders
 * @param {String} $root_selector (.site) - main wrapper element
 * @param {String} $root_footer_selector (.push) - hidden element that "pushes" down the footer
 * @param {String} $footer_selector (footer) - footer element
 * @example scss
 * .foobar { @include sticky-footer(4em) { ... } }
 */

@mixin sticky-footer($footer_height, $root_selector:".site", $root_footer_selector:".push", $footer_selector:"footer") {
  html, body {height: 100%;}
  #{$root_selector} {
    width: 100%;
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -#{$footer_height};
    #{$root_footer_selector} {
      height: #{$footer_height};
    }
  }
  #{$footer_selector} {
    clear: both;
    position: relative;
    height: #{$footer_height};
  }
}

/**
 * Fullscreen Background for < IE8
 * @link http://css-tricks.com/perfect-full-page-background-image/
 * @example scss
 * .foobar { @include fullscreen-bg() { ... } }
 */

@mixin fullscreen-bg() {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
  }
}

/**
 * @param {number} $opacity
 * @example scss
 * .foobar { @include opacity(4) { ... } }
 */
@mixin opacity($opacity) {
  filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
  opacity: $opacity;

  /* Hack IE6 */
  .lt-ie7 & {
    filter : alpha(opacity=#{round($opacity * 100)});
    zoom: 1;
  }
}

/**
 * @link http://bit.ly/thoughtbot-bourbon-inline-block
 * @require {mixin} old-ie
 * @param {String} $alignment (baseline)
 * @example scss
 * .foobar { @include inline-block() { ... } }
 */

@mixin inline-block($alignment: baseline) {
  display: inline-block;
  @if $alignment and $alignment != none {
    vertical-align: $alignment;
  }
  @include old-ie {
    zoom:1;
    *display:inline;
    *vertical-align:auto;
  }
}

/**
 * Retina Displays
 * @param {String} $image - image url
 * @param {String} $width - image width
 * @param {String} $height - image height
 * @example scss
 * .foobar { @include image-2x('img/logo.png', 200px, 300px) { ... } }
 */

@mixin image-2x($image, $width, $height) {

  @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {

    // on retina, use an image that's scaled by 2
    background-image: url($image);
    background-size: $width $height;
  }
}

/**
 * RGBA Fallback
 * @param {String} $color
 * @param {String} $percent
 * @example scss
 * .foobar { @include rgba-bg(#f37,.9) { ... } }
 */

@mixin rgba-bg($color, $percent) {
  background: $color;
  background: rgba($color, $percent);
}


@mixin gradient($color1, $color2) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4f5f9+0,e3e4e7+100 */
  background: $color1; /* Old browsers */
  background: -moz-linear-gradient(top,  rgba($color1,1) 0%, rgba($color2,1) 45%, rgba($color2,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba($color1,1)), color-stop(45%,rgba($color1,1)), color-stop(100%,rgba($color2,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba($color1,1) 0%,rgba($color2,1) 45%,rgba($color2,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba($color1,1) 0%,rgba($color2,1) 45%,rgba($color2,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba($color1,1) 0%,rgba($color2,1) 45%,rgba($color2,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba($color1,1) 0%,rgba($color2,1) 45%,rgba($color2,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 ); /* IE6-9 */

}

// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}

// how to use
// .test{
//    @include center(true, false);
// }
@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    @include prefix('transform', translateX(-50%) translateY(-50%));
  } @else if ($horizontal) {
    left: 50%;
    @include prefix('transform', translateX(-50%) translateY(0));
  } @else if ($vertical) {
    top: 50%;
    @include prefix('transform', translateX(0) translateY(-50%));
  }
}

// @include keyframes( incrediblehulk )
// {
//   0% {
//     color: #f8d2c0;
//     // you can also use mixins inside this mixin!
//     @include transform( scale(1) );
//   }

//   100% {
//     color: #0f0;
//     @include transform( scale(3) );
//   }
// }
@mixin keyframes( $animationName ){
  @-webkit-keyframes $animationName {
    @content;
  }
  @-moz-keyframes $animationName {
    @content;
  }
  @-o-keyframes $animationName {
    @content;
  }
  @keyframes $animationName {
    @content;
  }
}

// .overlay {
//   @include coverer;
//   background: rgba(black, 0.5);
//   z-index: 1000;
// }
@mixin coverer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// BORDER RADIUS
@mixin radius($radius: 3px) {
  @include prefix('border-radius', $radius);
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
  -webkit-border-top-left-radius:     $topLeftRadius;
  -webkit-border-top-right-radius:    $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius:  $bottomLeftRadius;

  -moz-border-radius-topleft:     $topLeftRadius;
  -moz-border-radius-topright:    $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft:  $bottomLeftRadius;

  border-top-left-radius:     $topLeftRadius;
  border-top-right-radius:    $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius:  $bottomLeftRadius;
}

// BOX SHADOW
@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if ($inset != "") {
    @include prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
    @include prefix('box-shadow', $x $y $blur $color);
  }
}

// BOX SIZING
@mixin box-sizing($type: border-box) {
  @include prefix('box-sizing', $type);
}

// COLUMNS
@mixin columns($count: 3, $gap: 10) {
  @include prefix('column-count', $count);
  @include prefix('column-gap', $gap);
}

@mixin transition($property: all, $speed: 300ms, $function: ease-in-out) {
  @include prefix('transition', $property $speed $function);
}

@mixin clearfix() {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  display: block;
}

@mixin noselect() {
  -webkit-touch-callout: none;
  @include prefix('user-select', none);
}

@mixin nostroke(){
  -webkit-text-stroke: 0px rgba(0,0,0,0);
}

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);
  @if $type == string {
    @if $min == xs {
      @media (max-width: 767px) { @content; }       // Mobile Devices
    }
    @else if $min == sm {
      @media (min-width: 768px) { @content; }       // Tablet Devices
    }
    @else if $min == md {
      @media (min-width: 992px) { @content; }       // Desktops
    }
    @else if $min == lg {
      @media (min-width: 1200px) { @content; }      // Widescreen Desktops
    }
    // Otherwise pass a warning to the compiler as to the appropriate options
    @else {
      @warn "The breakpoint mixin supports the following attributes: xs, sm, md, lg";
    }
  }
  @else if $type == number {
    // Allow for custom parameters for min and max size
    $query: "all" !default;
    @if      $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; } // set both min and max
    @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; } // set just min
    @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; } // set just max
    @media #{$query} { @content; }
  }
}

// Wraps interior content in a media query
@mixin br($querystring) {
  $valid: true;
  @if type-of($querystring) != "string" {
    @warn "querystring: #{$querystring} is not a string";
    $valid: false;
  }
  @if $valid == true {
    @media #{$querystring} {
      @content;
    }
  }
}

@mixin link() {
  @include transition;
  color: #040707;
  border-bottom: 1px solid #fe8585;
  box-shadow: inset 0 -7px 0 #fe8585;
  text-decoration: none;
  &:hover{
    background-color: #fe8585;
  }
}

@mixin searchfield {
  @include prefix(appearance, none);
  @include prefix(box-shadow, none !important);
  width: 100%;
  background-color: $trans;
  border: 0px !important;
  font-size: 24px;
  color: rgba(49, 45, 46, 1);
  text-align: left;
}