.modul-iklan-container{
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  &.iklan-leaderboard{
    margin-bottom: 0;
  }
  // padding: 15px;
  .container, .row, .col-sm-12{
    height: 100%;
  }
  .iklan{
    text-align: center;
  }
  img{
    max-width: 100% !important;
  }
}

// .iklan-leaderboard{
//   display: none;
// }