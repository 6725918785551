.page-template-template-subpages{
  .wrap{
    padding-top: 30px;
    margin-bottom: 20px;
  }
  .title{
    margin-top: 0px;
  }
}

.sidemenu{
  ul{
    li{
      margin-bottom: 1px;
      &.active{
        a{
          background-color: $body-color;
          color: $white;
        }
      }
      a{
        font-size: 18px;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        color: $body-color;
        -webkit-font-smoothing: antialiased !important;
        text-rendering: optimizeLegibility !important;
        &:hover{
          background-color: $body-color;
          color: $white;
        }
      }
    }
  }
}