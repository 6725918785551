.metaslider{
    .flexslider{
        .slides{
            border: 1px solid #eee !important;
            border-radius: 3px;
            overflow: hidden;
        }
        .caption-wrap{
            width: auto;
            display: inline-block;
            bottom: 10px;
            left: 10px;
            opacity: 1;
            background-color: transparent;
            z-index: 999;
            &::after{
                content: ' ';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0,0,0,.5);
            }
            .caption{
                position: relative;
                z-index: 1;
            }
        }
        .flex-control-paging li a{
            width: 5px;
            height: 5px;
        }
        .flex-control-nav{
            bottom: 0;
        }
    }
}