.email-signup-container{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
  margin-bottom: 30px;
  position: relative;
  .cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .6);
  }
  .body-text{
    padding: 35px 0 5px;
    h3{
      font-size: 26px;
      font-weight: bold;
    }
    p{
      font-size: 18px;
    }
  }
  .sign-up-form-wrapper{
    text-align: center;
    padding: 40px 0;
    input[type="email"]{
      width: 340px;
      max-width: 100%;
      font-size: 18px;
      color: rgba(52, 52, 52, 1);
      text-align: left;
      border: 1px solid #eee;
      height: 38px;
      padding: 5px 10px;
      margin-bottom: 10px;
    }
    input[type="submit"]{
      margin-left: 15px;
      font-size: 14px;
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      border-width: 3px;
      height: 38px;
      padding: {
        left: 30px;
        right: 30px;
      }
      border-color: rgb(255, 0, 0);
      border-style: solid;
      @include prefix(border-radius, 20px);
      background-color: rgb(255, 0, 0);
    }
  }
}