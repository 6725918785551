body{
  &.search{
    padding-top: 70px;
    @include breakpoint(0, 991px){
      padding-top: 0;
      .wrap{
        padding-top: 100px;
      }
    }
    
    .search-form-wrapper{
      padding: {
        top: 40px;
        bottom: 40px;
      }
    }
    .search-form{
      position: relative;
      label{
        width: 100%;
        border-bottom: 1px solid rgba(82, 82, 82, .6);
      }
      .search-submit{
        position: absolute;
        right: 0;
        top: 0;
        font-size: 24px;
        @include prefix(appearance, none);
        @include prefix(box-shadow, none !important);
        outline: none !important;
        background-color: $trans !important;
        border: 0px !important;
        font-size: 24px;
        color: rgba(49, 45, 46, .5);
        line-height: 25px;
        text-align: left;
      }
    }
    .search-field{
      @include searchfield;
      padding: 10px 0;
    }

  }
}