.page-template-template-services{
  .sidebar-wrapper{
    z-index: 0;
  }
  .shop-row{
    padding-bottom: 0;
  }
  .blog-category__container{
    padding: 20px 0 40px 0;
  }
}

body.page-template-template-iframe{
  background-color: transparent !important;
  header,
  header.mobile,
  footer{
    display: none !important;
  }

  .logo{
    img{
      display: inline-block;
    }
  }

  .iframe-wrapper{
    background-color: #fff;
    padding: 20px;
  }
}