.thecut-login-form{
  background-color: $white;
  padding: 43px 79px;
  margin: 60px 0;
  p{
    font-size: 16px;
  }
  .title{
    font-size: 26px;
    color: rgb(52, 52, 52);
    font-weight: bold;
    margin-top: 0px;
  }
  .fb-login{
    margin-bottom: 30px;
  }
  .wp-social-login-provider-list{
    padding: 0px;
    a{
      display: block;
      width: 100%;
      height: 33px;
      text-align: center;
      vertical-align: middle;
      padding: 6px;
      img{
        display: none;
      }
      span{
        @include prefix(transform, translateY(3px));
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
      }
    }
    .wp-social-login-provider-facebook,
    .wp-social-login-provider-twitter{
      background-color: #3388ce;
      color: $white;
      margin-bottom: 5px;
      &:hover{
        background-color: darken(#3388ce, 10%);
        text-decoration: none;
      }
      span{
        background-image: url(../images/fb-login.svg);
        width: 16px;
        margin-right: 5px;
      }
    }
    .wp-social-login-provider-twitter{
      background-color: #00b6f1;
      &:hover{
        background-color: darken(#00b6f1, 10%);
        text-decoration: none;
      }
      span{
        background-image: url(../images/tw.svg);
      }
    }
    
  }
}

.tml{
  width: 100%;
  max-width: 100%;
  form{
    p{
      margin-bottom: 3px;
    }
    label{
      font-size: 14px;
      font-weight: bold;
    }
    input[type="text"],
    input[type="password"]{
      width: 100%;
      font-size: 18px;
      color: rgba(52, 52, 52, 1);
      text-align: left;
      border: 1px solid #eee;
      height: 38px;
      padding: 5px;   
    }
    .tml-rememberme-wrap{
      display: block;
      width: 100%;
      img{
        width: 18px;
        height: 18px;
      }
      label{
        font-size: 16px;
        color: rgb(52, 52, 52);
        font-weight: normal;
      }
    }
    
    .tml-submit-wrap{
      display: block;
      width: 100%;
      text-align: center;
      #wp-submit{
        height: 39px;
        width: 100%;
        margin-top: 15px;
        background-color: red;
        border: 0px;
        font-size: 14px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        text-transform: uppercase;
        &:hover{
          background-color: darken(red, 10%);
        }
      }
    }
  }
  .tml-action-links{
    a{
      font-size: 16px;
      color: red;
    }
  }
}
.tml-login{
  .wp-social-login-widget{
    display: none;
  }
}
.my-account{
  .thecut-account{
    .nav{
      li{
        &.active{
          a{
            font-weight: bold;
          }
        }
        a{
          font-size: 14px;
          color: rgb(52, 52, 52);
        }
      }
    }
    .tab-content{
      .title{
        margin-top: 8px;
        margin-bottom: 20px;
        font-size: 26px;
        color: rgb(52, 52, 52);
        font-weight: bold;
      }
      form, table{
        max-width: 395px;
      }
      input[type="submit"]{
        max-width: 192px;
      }
    }
  }
}