.modal-content{
  @include radius(0px);
}

.video-modal{

  .modal-header{
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    background-color: $white;
    z-index: 1;
  }

  .modal-title,
  .modal-footer{
    display: none;
  }

  .modal-body{
    padding: 0;
  }

}

body{
  &.newsletter-popup{
    .woo-popup-cover,
    .woo-popup-wrap{
      display: block;
    }
  }
}
.woo-popup-cover{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9993;
  background-color: #000;
  opacity: .3;
  display: none;
}

.woo-popup-wrap{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9993;
  overflow-y: auto;
  display: none;
}
.woo-popup-class{
  @include prefix(transform, translateX(-50%) translateY(-50%));
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  padding: 0;
  width: 100%;
  max-width: 700px;
  > div{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 100%;
  }
  .left{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  .right{
    position: relative;
    margin-left: 50%;
    padding: 20px 35px;
  }
  .popup-image{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .popup-close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    img{
      display: block;
      width: 25px;
      height: 25px;
    }
  }
  .popup-title{
    margin-top: 0;
    p{
      font-size: 25px;
    }
  }
  .popup-logo{
    text-align: center;
    margin-bottom: 30px;
    img{
      display: inline-block;
      max-width: 144px;
    }
  }
  .popup-desc,
  .popup-hide{
    p{
      font-size: 14px;
      font-family: $global-font;
      color: #5e5e5e;
    }
  }
  .terms{
    color: #5e5e5e;
    font-size: 12px;
  }
  .popup-facebook{
    position: relative;
    .wp-social-login-provider-list{
      padding: 0;
    }
    .wp-social-login-provider{
      @include prefix(box-sizing, border-box);
      display: block;
      width: 100%;
      background-color: #325a9d;
      height: 42px;
      color: $white;
      text-align: center;
      vertical-align: middle;
      padding: 10px;
      margin-bottom: 5px;
      &:hover{
        background-color: darken(#3388ce, 10%);
        text-decoration: none;
      }
      span{
        @include prefix(transform, translateY(3px));
        background-image: url(../images/fb-login.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      img{
        display: none;
      }
      &.wp-social-login-provider-twitter{
        background-color: #00b6f1;
        &:hover{
          background-color: darken(#00b6f1, 10%);
        }
        span{
          background-image: url(../images/tw.svg);
        }
      }
    }
  }
  .popup-or{
    p{
      position: relative;display: block;
      &::before{
        display: block;
        position: absolute;
        border-bottom: 1px solid #dddddd;
        content: '';
        width: 100%;
        top: 15px;
      }
      span{
        display: inline-block;
        background-color: $white;
        position: relative;
        padding: 0 10px;
        font-size: 14px;
      }
    }
  }
  .popup-form{
    margin-top: 10px;
    input[type="submit"]{
      @include prefix(border-radius, 0px);
      width: 100% !important;
      display: block !important;
      margin-top: 0 !important;
      background-color: #008ad3;
      color: $white;
      font-weight: normal !important;
      border: 0px !important;
      &:hover,&:focus{
        background-color: darken(#008ad3, 10%);
      }
    }

    form{
      p{
        &:first-of-type{
          margin-bottom: 0;
          > *{
            display: inline-block;
          }

          .wpcf7-form-control-wrap{
            width: 65%;
            margin-right: 5%;
            .wpcf7-text{
              height: 47px;
            }
          }
          input[type="submit"]{
            width: 30%;
          }
        }
      }
      ul.gform_fields{
        padding-left: 0;
        list-style: none;
      }
      .ginput_container_email{
        input{
          width: 100%;
          padding: 10px;
        }
      }
      .gform_validation_container{
        display: none;
      }
    }
  }
}

@media ($mobile) {
  .woo-popup-class{
    @include prefix(transform, none);
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    padding: 30px 20px;
  }
}
@media ($small-screen) {
  .woo-popup-class{
    padding: 0;
    bottom: 0;
    .left{
      width: 100%;
      height: 200px;
      // display: none;
    }
    .right{
      margin-left: 0px;
      margin-top: 200px;
      width: 100%;
    }
  }
}