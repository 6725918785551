%category{
  font-size: 22px;
  color: red;
  font-weight: bold;
  text-transform: uppercase;
  a{
    &:hover{
      text-decoration: none;
    }
  }
}
%tags{
  font-size: 18px;
  color: #a1a1a1;
  a{
    color: #a1a1a1;
    text-decoration: none;
  }
}

// blog list page
.featured-post__container,
.blog-list__container,
.category-grid__container,
.blog-category__container{
  background-color: #fff;
  position: relative;
}
.blog-category__container{
  padding: 40px 0;
  @media ($mobile) {
    padding: 20px 0;
  }
  select{
    display: none;
  }
  .red-select{
    display: none;
    @media ($mobile) {
      display: block;
    }
    > li{
      > span{
        padding-right: 50px;
      }
    }
  }
  .transformSelectDropdown{
    li{
      display: block;
      &:first-of-type{
        // display: none;
      }
      &:after{
        content: '';
      }
    }
  }
  ul{
    text-align: center;
    margin: 0;
    padding: 0;
    li{
      display: inline-block;
      position: relative;
      &:last-of-type{
        &:after{
          content: '';
        }
      }
      &:after{
        content: '|';
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        color: #a1a1a1;
      }
      &.current-cat{
        a{
          color: red;
        }
      }
      a{
        text-transform: uppercase;
        font-size: 22px;
        line-height: 28px;
        color: #a1a1a1;
        font-weight: bold;
        padding: 0 10px;
        &:hover,
        &:focus{
          text-decoration: none;
        }
      }
    }
  }
}

#blog-post-list{
  > .col-sm-4{
    margin-bottom: 35px;
  }
}

.archive,
.category{
  .blog-category__container{
    select{
      display: none;
    }
    .red-select{
      display: none;
      @media ($mobile) {
        display: block;
      }
    }
    li{
      &.cat-item-all{
        a{
          color: #a1a1a1;
        }
      }
    }
  }
}

.featured-post__container{
  .col-sm-6{
    margin-bottom: 40px;
    @media ($mobile) {
      margin-bottom: 20px;
    }
    > a{
      display: block;
      background-color: #000;
    }
    &:hover{
      .feat-post__image{
        opacity: 1;
      }
      .feat-post__title{
        a{
          color: lighten(#343434, 20%);
        }
      }
    }
  }
  .feat-post__image{
    @include transition;
    width: 100%;
    display: block;
    height: 359px;
    background-size: cover;
    background-position: center;
    opacity: .9;
    @media ($mobile) {
      height: 240px;
    }
  }
  .feat-post__category{
    margin-top: 21px;
    margin-bottom: 0px;
    @media ($mobile) {
      margin-top: 5px;
    }
    a{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      text-decoration: none;
      color: red;
    }
  }
  .feat-post__title{
    font-size: 26px;
    line-height: 28px;
    font-weight: bold;
    @media ($mobile) {
      margin-top: 0;
      font-size: 22px;
      line-height: 23px;
      margin-bottom: 0;
    }
    a{
      @include transition;
      color: #343434;
      text-decoration: none;
    }
  }
  .feat-post__content{
    font-size: 18px;
    color: #343434;
    @media ($mobile) {
      display: none;
    }
  }
  .feat-post__tags{
    @extend %tags;
    @media ($mobile) {
      font-size: 16px;
    }
  }
}

.blog-list__container{
  padding-bottom: 50px;
  .news__post{
    a{ }
  }
  .text-center{
    .button.button-trans{
      margin-top: 20px;
    }
  }
}

.single-header-cover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
}

/* single post */
.single{
  article{
    header{
      position: relative;
      &.full-height{
        .header-hero__title{
          @include prefix(transform, translateY(-50%));
          position: relative;
          top: 50%;
          @media ($mobile) {
            top: inherit;
            @include prefix(transform, translateY(0));
          }
        }
      }
      .header-hero{
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media ($mobile) {
          min-height: inherit !important;
          height: inherit !important;
        }
      }
      .header-hero__title{
        @extend %category;
        padding: 130px 20px;
      }
      .header_category{
        @media ($mobile) {
          a{
            font-size: 18px;
          }
        }
      }
      .header_title{
        color: $white;
        margin-top: 0;
        font-weight: bold;
        font-size: 65px;
        line-height: 56px;
        @media ($mobile) {
          font-size: 35px;
          line-height:34px;
          text-transform: none;
        }
        @media (min-width: 1300px) {
          font-size: 75px;
          line-height: 75px;
        }
        a{
          color: $white;
          text-decoration: none;
        }
      }
    }

    .top-bar{
      margin: {
        top: 40px;
        bottom: 40px;
      }
      @media ($mobile) {
        margin: {
          top: 10px;
          bottom: 10px;
        }
      }
      p{
        font-size: 21px;
        color: #a1a1a1;
        margin: 0;
        @media ($mobile) {
          font-size: 16px;
        }
      }
      .category{
        @extend %category;
        @media ($mobile) {
          font-size: 14px;
        }
      }
      .tags, .date{
        @extend %tags;
        @media ($mobile) {
          font-size: 16px;
        }
      }
      .span-fb-like{
        @media ($mobile) {
          margin-left: 10px;
          padding-top: 10px;
        }
      }
    }

    .post-data{
      h3{
        font-size: 26px;
        line-height: 28px;
        font-weight: bold;
        margin-top: 0;
      }
      img{
        margin-bottom: 10px;
      }
    }
  }
}

.header-no-image{
  .header_title{
    h2{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 65px;
      line-height: 56px;
      @media ($mobile) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.top-bar{
  position: relative;
  .share{
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    @media ($mobile) {
      top: auto;
      bottom: 8px;
    }
    a{
      color: #a1a1a1;
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.editor-pick__title{
  font-size: 65px;
  color: rgb(52, 52, 52);
  font-weight: bold;
  margin-bottom: 30px;
  @media ($mobile) {
    font-size: 35px;
  }
}
.editor-pick__post{
  a{
    display: block;
    .img{
      display: block;
      height: 360px;
      width: 100%;
      background-size: cover;
      background-position: center;
      @include breakpoint(768px, 992px){
        height: 250px;
      }
      @include breakpoint(0, 767px){
        height: 91px;
      }
    }
  }
  .category{
    @extend %category;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    @media ($mobile) {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
  .title{
    a{
      font-size: 26px;
      font-weight: bold;
      color: #343434;
      &:hover,
      &:focus{
        text-decoration: none;
      }
      @media ($mobile) {
        font-size: 16px;
      }
    }
  }
  .intro{
    @media ($mobile) {
      display: none;
    }
    p{
      font-size: 18px;
    }
  }
  .meta{
    @extend %tags;
    @media ($mobile) {
      font-size: 12px;
    }
  }
}

#sidebar{
  padding-left: 30px;
  > ul{
    list-style: none;
    margin: 0;
    padding: 0;
    h2{
      font-size: 26px;
      line-height: 28px;
      font-weight: bold;
    }
    ul{
      padding-left: 20px;
    }
  }
}

.widget_weekly_popular{
  @media ($mobile) {
    display: none;
  }
}

.widget{
  h3{
    margin: 0;
    font-size: 26px;
    font-family: $global-font;
    color: rgb(52, 52, 52);
    font-weight: bold;
    line-height: 28px;
  }
  &.widget_pplrpsts_popular_posts_widget,
  &.widget_weekly_popular,
  &.tpp_widget {
    .pplrpsts-popular-posts,
    .tpp-popular-posts {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    article{
      padding-bottom: 10px;
      border-bottom: 2px solid #ededed;
      margin-bottom: 10px;
    }
    .entry-header{
      a{
        font-size: 12px;
        font-family: $global-font;
        color: rgb(255, 0, 0);
        font-weight: bold;
        text-transform: uppercase;
        &:hover{
          text-decoration: none;
        }
      }
      .pplrpsts_posts_title{
        margin: 0;
        a{
          font-size: 16px;
          font-family: $global-font;
          color: rgb(52, 52, 52);
          font-weight: bold;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .entry-content{
      position: relative;
      z-index: 1;
      a{
        .img{
          @include prefix(box-sizing, content-box);
          padding: 0;
          float: right;
          padding-left: 10px;
          width: 155px;
          height: 105px;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}

.post-data{
  margin-bottom: 20px;
  table{
    width: 100%;
    img{
      width: 100%;
    }
  }
}

.post-card,
.news__post{
  position: relative;
  margin-bottom: 48px;
  background-color: $white;
  display: inline-block;
  width: 100%;
  .featured-image{
    display: block;
    width: 100%;
    height: 220px;
    background-color: #cccccc;
    a{
      display: block;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }
    .img{
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .white-area{
    padding: 0 15px 15px;
  }
  .categories{
    @extend %category;
    font-size: 14px;
    padding: 10px 0;
  }
  .entry-title{
    margin: 0 0 20px;
    font-size: 26px;
    color: $body-color;
    font-weight: bold;
    a{
      color: $body-color;
    }
    &:hover{
      a{
        color: #676767;
        text-decoration: none;
      }
    }
  }
  .entry-summary p {
    font-size: 16px;
  }
  
  .meta{
    p{
      font-size: 14px;
      color: $grey;
      margin: 0;
      a{
        color: $body-color;
        font-weight: bold;
        text-transform: capitalize;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }

}
.post-card-iklan{
  background-color: $trans;
}
.card-iklan{
  text-align: center;
  a{
    display: inline-block;
  }
}
.card-newsletter{
  width: 100%;
  background-color: $white;
  padding: 20px;
  position: absolute;
  bottom: 0;
  &.theme-grey{
    background-color: #282828;
    h3{
      color: $white !important;
    }
  }
  &.theme-red{
    background-color: red;
    h3{
      color: $white !important;
    }
    input[type="submit"]{
      background-color: #282828 !important;
    }
  }
  h3{
    font-size: 25px;
    line-height: 26px;
    color: $brand-primary;
    font-weight: bold;
    line-height: 1.077;
    margin: 0 0 20px;
  }
  
  form{
    .mc4wp-form-fields{
      position: relative;
      display: block;
      vertical-align: top;
    }
    input[type="email"]{
      font-size: 18px;
      color: rgba(52, 52, 52, 0.69);
      text-align: left;
      width: 70%;
      display: inline-block;
      border-width: 1px;
      border-color: rgb(239, 239, 239);
      border-style: solid;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
    input[type="submit"]{
      display: inline-block;
      width: 30%;
      height: 40px;
      float: right;
      margin-left: -3px;
      background-color: red;
      text-transform: uppercase;
      color: $white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      border: 1px solid red;
      &:hover,&:focus{
        background-color: darken(red, 10%);
      }
    }
  }
}

.thecut-gallery{
  .gallery-item{
    position: relative;
    .index{
      display: none;
      background-color: rgba(#333333, .4);
      color: $white;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 10px 20px;
      @include prefix(border-radius, 30px);
    }
  }
}

.thecut-slideshow{
  margin-bottom: 10px;
  .gallery-item{
    background-color: #000;
    .index{
      display: inline-block;
    }
    img{
      display: block;
      // width: auto;
      // height: 100%;
      margin: 0 auto !important;
      &.img-portrait{
        width: auto;
      }
    }
  }
  .slick-arrow{
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 1;
    background-color: rgba(#333333, .4);
    width: 54px;
    height: 110px;
    margin-top: -55px;
    cursor: pointer;
    @include prefix(border-radius, 10px);
    i{
      @include center(true, true);
      color: $white;
      font-size: 25px;
    }
    &.hero-next-arrow{
      right: 10px;
      left: auto;
    }
  }
}

@include breakpoint(0, 991px){
  .single{
    .site-wrap{
      > .wrap{
        padding-top: 70px;
      }
    }
  }
}
@include breakpoint(768px, 991px){
  .post-card,
  .post-card-iklan {
    .featured-image, 
    .card-iklan{
      float: left;
      width: 30%;
      img{
        max-width: 100%;
      }
    }
    .white-area,
    .card-newsletter{
      margin-left: 30%;
      height: 100%;
    }
  }
}
@include breakpoint(0, 767px){
  .card-newsletter{
    position: relative;
  }
}