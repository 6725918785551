.video-container{
  background-color: #282828;
  padding: 65px 0;
  margin-bottom: 40px;
}

.video-player-wrapper{
  height: 315px;
  #video-player{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .play{
      background-image: url(../images/play.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 102px;
      height: 102px;
      z-index: 9;
      @include center(true, true);
      cursor: pointer;
    }
  }
}
#thecut-ytplayer{
  width: 100%;
  height: 100%;
  position: relative;
}

.video-list{
  height: 315px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track{
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	background-color: #000000;
  }

  &::-webkit-scrollbar{
  	width: 10px;
  	background-color: #000000;
  }

  &::-webkit-scrollbar-thumb{
  	background-color: #f5f5f5;
  	border: 2px solid #555555;
  }
}
.video-item{
  height: 105px;
  > *{
    display: inline-block;
  }
  .video-thumbnail{
    position: relative;
    width: 40%;
    height: 100%;
    padding: 10px;
    .play{
      background-image: url(../images/play.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 44px;
      height: 44px;
      z-index: 9;
      @include center(true, true);
    }
    .img{
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .title-wrap{
    position: relative;
    height: 100%;
    width: 58%;
    vertical-align: top;
    padding: 10px;
    .title{
      @include center(false, true);
      color: $white;
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}