.category-grid__container{
  padding-bottom: 40px;
  padding-top: 10px;
  @media ($mobile) {
    padding-bottom: 20px;
  }
  .category-item{
    position: relative;
  }
}

.ul__category-grid{
  position: relative;
  .category-item{
    display: inline-block;
    width: 33.333333%;
    @media ($mobile) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  @media ($not-mobile) {
    .category-item{
      &:hover{
        .category-item__hover{
          opacity: 1;
        }
      }
    }
  }
  .category-item__content{
    height: 300px;
    padding-top: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .category-item__hover{
    @include transition;
    position: absolute;
    background-color: $category-hover-bg;
    opacity: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
  }

  .item-content__text{
    p{
      font-size: $category-title-size;
      font-weight: $category-title-weight;
      color: $white;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .item-hover__content{
    position: relative;
    display: block;
    height: 100%;
    p{
      font-size: $category-hover-text-size;
      font-weight: $category-hover-text-weight;
      color: $white;
      padding: 0 30px;
      position: relative;
      top: 50%;
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}