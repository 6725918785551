// Glyphicons font path
$icon-font-path:        "../fonts/";
$img: "../images/";

$container-large-desktop: 1200px;

$screen-sm: 991px !default;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         red;
$white:                 #ffffff;
$black:                 #000000;
$red:                   red;
$trans:                 transparent;

$body-color:            #343434;
$grey:                  #a1a1a1;

$small-screen: 'max-width: 760px';
$mobile: 'max-width: 991px';
$not-mobile: 'min-width: 992px';
$tablet: '(min-width: 992px) and (max-width: 1024px)';

// footer
$footer-bg: $body-color;

$header-bg: $trans;
$nav-color: $white;
$hero-color: $white;
$hero-desc: $white;
$submenu-bg: #282828;
//--
$news-item-category: $red;
$news-item-meta: $grey;
//--
$add-bg: $body-color;
//--
$category-hover-bg: rgba($red, .8);

// Fonts

@font-face {
  font-family: 'Selima';
  src: url($icon-font-path + 'Selima .eot');
  src: local('☺'), url($icon-font-path + 'Selima .woff') format('woff'), url($icon-font-path + 'Selima .ttf') format('truetype'), url($icon-font-path + 'Selima .svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$global-font:           'Source Sans Pro', sans-serif;
$selima:                'Selima', sans-serif;

$btn-trans-img: url($img + 'trans-btn.png');
$hero-arrow-img: url($img + 'hero-arrow.png');
$hero-play-img: url($img + 'play.svg');

$cup-shadow-img: url($img + 'shadow.png');

$ig-nav-prev: url($img + 'prev.png');
$ig-nav-next: url($img + 'next.png');

$featured-nav: url($img + 'circle-arrow.svg');

// select
$select-arrow: url($img + 'arrow-down.svg');

// Typography
  // button
  $btn-size: 14px;
  $btn-title-weight: bold;
  // header
  $nav-menu-size: 14px;
  $nav-menu-color: $white;
  $nav-menu-weight: 600;

  // hero
  $hero-title-size: 130px;
  $hero-title-color: $white;
  $hero-title-weight: 600;
  //--
  $hero-desc-size: 18px;
  $hero-desc-color: $white;
  $hero-desc-weight: normal;

  // module
    // news
    $news-title-size: 65px;
    $news-title-weight: bold;
    //--
    $news-item-title-size: 26px;
    $news-item-title-weight: bold;
    //--
    $news-item-category-size: 14px;
    $news-item-category-weight: bold;
    //--
    $news-item-meta-size: 18px;
    $news-item-meta-weight: normal;
    // featured products
    $featured-title-size: 115px;
    $featured-title-weight: bold;
    // 2 column
    $column-title-size: 65px;
    $column-title-weight: bold;
    //--
    $column-desc-size: 18px;
    $column-desc-weight: normal;
    // newsletter
    $newsletter-title-size: 30px;
    $newsletter-title-weight: bold;
    $newsletter-subtitle-size: 18px;
    $newsletter-subtitle-weight: normal;
    // instagram
    $instagram-title-size: 18px;
    $instagram-title-weight: bold;
    // youtube
    $youtube-title-size: 18px;
    $youtube-title-weight: bold;
    $youtube-item-title-size: 16px;
    $youtube-item-title-weight: bold;
    // category
    $category-title-size: 35px;
    $category-title-weight: bold;
    $category-hover-text-size: 26px;
    $category-hover-text-weight: bold;
    // store grid
    $store-grid-title-size: 18px;
    $store-grid-title-weight: normal;

  // page
    // contact
    $contact-page-title: 65px;
    $contact-page-weight: bold;