.social-media__container{
  position: relative;
  margin-bottom: 40px;
}
.social-media__background{
  width: 100%;
  position: absolute;
  height: 333px;
  top: 123px;
  z-index: -1;
}
.social-media__instagram{
  margin-bottom: 60px;
}
.instagram__grid{

}
.instagram__item{
  a,.instagram__item-img{
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 230px;
    @include breakpoint(768px, 1024px){
      height: 236px;
    }
    @include breakpoint(0, 767px){
      height: 97px;
    }
  }
}

.instagram__title,
.youtube__title{
  margin-top: 17px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.ig-nav{
  background-image: $ig-nav-prev;
  width: 35px;
  height: 35px;
  background-position: center;
  background-size: 100% 95%;
  background-repeat: no-repeat;
  display: inline-block;
  cursor: pointer;
  &.next{
    background-image: $ig-nav-next;
  }
}

.youtube__item{
  float: left;
  position: relative;
  width: 33.3333%;
  a{
    display: block;
    background-color: $black;
  }
}
.youtube__item-text{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 25px;
  color: $white;
  font-size: 16px;
  font-weight: bold;
  i{
    color: $white;
    font-style: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    width: 100%;
    display: block;
    height: 48px;
    @media ($mobile) {
      height: 35px;
    }
  }
  @media ($mobile) {
    padding: 5px;
    font-size: 12px;
  }
}
.youtube__item-img{
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .7;
  position: relative;
  width: 100%;
  height: 100%;
}