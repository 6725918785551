.white-header,
.header-hover,
.page-scroll,
.open-search,
body.search,
body.archive,
body.login,
body.lostpassword,
body.register,
body.my-account,
body.no-hero .banner{
  .svg-img{
    path{
      fill: $body-color;
    }
  }

  // padding-top: 50px;

  header{
    background-color: $white;
  }

  .nav-primary{
    .nav{
      li{
        a{
          color: $body-color;
        }
      }
    }
  }

  .right-menu{
    a{
      color: $body-color;
    }
  }
  .divider{
    color: $body-color;
  }

  .cart-content{
    color: $white;
  }
}

body{
  &.archive{
    .site-wrap{
      > .wrap{
        padding-top: 130px;
      }
    }
  }
}

.white-header,
.page-scroll,
.header-hover,
.search,
.archive,
.login,
.lostpassword,
.register,
.my-account,
.open-search{
  .logo-white{
    display: inline-block !important;
  }
  .logo-dark{
    display: none !important;
  }
}

.white-header,
// .login,
// .register,
// .my-account,
// .lostpassword
{
  .site-wrap{
    padding-top: 76px;
  }
  header.mobile{
    @media ($mobile) {
      background-color: $white;
    }
  }
}

.page-scroll{
  header{
    &.banner{
      position: fixed !important;
      top: 0;
      left: 0;
    }
  }
  .sub-menu-wrap{
    top: 70px !important;
    // position: fixed !important;
  }
}

.hasLeaderboard{
  header{
    &.banner{
      position: absolute;
    }
  }
}

header.mobile{
  &.sticky{
    position: fixed;
    top: 0px !important;
    .mobile-inline-menu{
      display: none;
    }
  }
}

.menu-active{
  overflow: hidden;
  header.mobile{
    background-color: #282828 !important;
  }
  #mobile-menu{
    @include prefix(transform, translateX(0));
  }
  .burger-menu{
    span{
      background-color: #fff !important;
      &:nth-child(1){
        @include prefix(transform, rotate(45deg) translate(4px, 5px));
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(3){
        @include prefix(transform, rotate(-45deg) translate(4px, -4px));
      }
    }
  }
}
