*{
  outline: none;
  font-family: $global-font;
  // color: $body-color;
}

html, body{
  font-family: $global-font;
  color: $body-color;
  background-color: #efefef;
}
html.my-account-html,
body.single{
  background-color: $white;
  body{
    background-color: $white;
  }
}
.separator-line{
  display: block;
  background-color: #ededed;
  height: 2px;
  width: 100%;
  margin: 0;
}
.margin-10-0{
  margin: 10px 0;
}
.separator--20{
  width: 100%;
  height: 20px;
  margin: 0;
  display: block;
  position: relative;
}
.separator--30{
  width: 100%;
  height: 30px;
  margin: 0;
  display: block;
  position: relative;
}
.separator--40{
  width: 100%;
  height: 40px;
  margin: 0;
  display: block;
  position: relative;
}
p{
  font-size: 18px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
}
h2{
  font-size: 50px;
}
.red{
  color: red !important;
}
img{
  max-width: 100%;
}
div.pp_overlay{
  z-index: 99991;
}
div.pp_pic_holder{
  z-index: 99992;
}

.ads__container{
  overflow: hidden;
  padding: 15px;
  div{
    height: 100%;
  }
  img{
    margin: 0 auto;
  }
  &.self{
    a{
      display: block;
    }
  }
}

iframe{
  max-width: 100% !important;
}