.button{
  @include transition;
  padding: 10px 20px;
  font-size: $btn-size;
  font-weight: $btn-title-weight;
  line-height: 17px;
  text-transform: uppercase;
  position: relative;
  height: 42px;
  border-radius: 0px;
  border: 3px solid;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  -moz-border-radius: 20px 20px/20px 20px;
  border-radius: 20px 20px/20px 20px;
  outline: none !important;
  &:active{
    -webkit-box-shadow:inset 0 0 0 0 rgba(0,0,0,0);
    box-shadow:inset 0 0 0 0 rgba(0,0,0,0);
  }
  &.important{
    padding: 10px 20px !important;
    font-size: $btn-size !important;
    font-weight: $btn-title-weight !important;
    line-height: 17px !important;
    text-transform: uppercase !important;
    position: relative !important;
    height: 42px !important;
    border-radius: 0px !important;
    border: 3px solid !important;
    background-size: 100% 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    -moz-border-radius: 20px 20px/20px 20px !important;
    border-radius: 20px 20px/20px 20px !important;
    outline: none !important !important;
    @media ($mobile) {
      padding: 10px !important;
    }
    &:active{
      -webkit-box-shadow:inset 0 0 0 0 rgba(0,0,0,0) !important;
      box-shadow:inset 0 0 0 0 rgba(0,0,0,0) !important;
    }
    &.block{
      width: 100% !important;
      float: none !important;
      display: block !important;
    }
  }
}

.button-main{
  color: $white;
  background-color: $red;
  border-color: $red;
  &:hover,&:focus{
    color: $red;
    background-color: $white;
    border-color: $red;
  }
  &.important{
    color: $white !important;
    background-color: $red !important;
    border-color: $red !important;
    &:hover,&:focus{
      color: $red !important;
      background-color: $white !important;
      border-color: $red !important;
    }
  }
}
.button-trans{
  color: $red;
  background-color: $white;
  border-color: $red;
  &:hover, &:focus{
    color: $white;
    background-color: $red;
    border-color: $red;
  }
  &.important{
    color: $red !important;
    background-color: $white !important;
    border-color: $red !important;
    &:hover, &:focus{
      color: $white !important;
      background-color: $red !important;
      border-color: $red !important;
    }
  }
}
.button-woocommerce{
  color: $white;
  background-color: #737373;
  border-color: #737373;
  &:hover, &:focus{
    color: #737373;
    background-color: $white;
    border-color: #737373;
  }
  &.important{
    color: $white !important;
    background-color: #737373 !important;
    border-color: #737373 !important;
    &:hover, &:focus{
      color: #737373 !important;
      background-color: $white !important;
      border-color: #737373 !important;
    }
  }
}

#payment{
  .place-order{
    margin-bottom: 0px;
    padding-bottom: 0px !important;
  }
}