// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.wpcf7-text,
.wpcf7-textarea,
.wccpf-field{
  @extend .form-control;
  @include radius(0px);
  border: 2px solid #e1e1e1 !important;
  font-size: 18px;
  height: 38px;
  &::-webkit-input-placeholder { color: $body-color; opacity: 1; }
  &::-moz-placeholder { color: $body-color; opacity: 1; } /* firefox 19+ */
  &:-ms-input-placeholder { color: $body-color; opacity: 1; } /* ie */
  &:-moz-placeholder { color: $body-color; opacity: 1; }

  &:focus{
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #9f9f9f !important;
  }
}

.wpcf7-checkbox{
  input{
    @include radius(0px);
    border: 2px solid #e1e1e1;
  }
  .wpcf7-list-item{
    margin: 0;
  }
}

.wpcf7-submit{
  margin-top: 20px;
  padding: {
    left: 49px;
    right: 49px;
  }
}

.red-select{
  padding: 0px;
  list-style: none;
  margin: 0;
  position: relative;
  > li{
    background-color: $white;
    border: 2px solid #e1e1e1;
    z-index: 8 !important;
  }
  span{
    padding: 6px 12px;
    display: block;
    font-size: 18px;
    height: 38px;
    cursor: pointer;
    position: relative;
  }
  .open{
    .arrow{
      @include prefix(transform, rotate(180deg));
    }
  }
  .arrow{
    @include transition;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -17px;
    width: 34px;
    height: 34px;
    content: '';
    background-image: $select-arrow;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% 50%;
  }
  select{
    width: 0px !important;
    max-width: 0% !important;
    min-width: 0% !important;
  }
}
.transformSelectDropdown{
  list-style: none;
  margin: 0;
  padding: 0;
  li{
    @include transition(background-color);
    &:hover{
      background-color: #e1e1e1;
    }
  }
}

.newsletter-subscribe{
  img{
    width: 22px;
    margin-right: 10px;
  }
}

.wccpf-field-layout-horizontal{
  label{
    position: relative;
    padding-left: 25px;
  }
}

.trans-element-radio{
  position: absolute;
  width: 22px;
  height: 22px;
  background-size: 100%;
  left: 0px;
  &.unchecked{
    background-image: url($img + 'checkbox.jpg');
  }
  &.checked{
    background-image: url($img + 'checkbox-checked.jpg');
  }
}

.account-field{
  margin-bottom: 15px;
  label{
    font-size: 14px;
    color: rgb(52, 52, 52);
    font-weight: bold;
    display: block;
  }
  input[type="text"],
  input[type="email"]{
    width: 100%;
    font-size: 18px;
    color: rgba(52, 52, 52, 1);
    text-align: left;
    border: 1px solid #eee;
    height: 38px;
    padding: 5px 10px;
    &#user_name{
      background-color: #eee;
    }
  }
  input[type="submit"]{
    height: 39px;
    width: 100%;
    margin-top: 15px;
    background-color: red;
    border: 0px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-transform: uppercase;
    &:hover{
      background-color: darken(red, 10%);
    }
  }
}

.gform_wrapper{
  margin: 0 !important;
}
.gfield{
  margin: 0 0 15px !important;
}
.gfield_label{
  display: none !important;
}