.stores-list__container{
  padding-bottom: 40px;
  padding-top: 20px;
  background-color: $white;
  position: relative;
  z-index: 1;
  @media ($mobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.stores-list__title{
  font-size: $store-grid-title-size;
  font-weight: $store-grid-title-weight;
  color: #b4b4b4;
}

.store-list__list{
  padding: 0;
  margin: 0;
  li{
    display: inline-block;
    width: 16.666%;
    @media ($mobile) {
      width: 33.3333%;
    }
    img{
      max-width: 100%;
    }
  }
}
.brand-footer{
  @media ($mobile) {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
}
footer{
  &.content-info{
    background-color: #292929;
    padding: 26px 0;
    position: relative;
    z-index: 5;
    @media ($mobile) {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
    .menu{
      margin: 0;
      padding: 10px 0 0 0;
      li{
        display: inline-block;
        margin-right: 20px;
        &:last-of-type{
          margin-right: 0px;
        }
        a{
          color: rgba($white, .7);
          text-decoration: none;
          font-size: 14px;
          &:hover{
            color: rgba($white, 1);
          }
        }
      }
      @media ($mobile) {
        margin-top: 10px;
        li{
          display: block;
          margin: 0;
          text-align: center;
          a{
            font-size: 16px;
            padding: 10px;
            display: block;
          }
        }
      }
    }

  }
  .copyright{
    padding-top: 11px;
    p{
      color: $white;
      margin: 0;
      font-size: 13px;
      b{
        font-weight: bold;
        color: $white;
      }
    }
  }
  ul.menu{
    text-align: left;
  }
}
.mobile-copyright{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}
footer.content-info.sticky-footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.mobile-copyright{
  background-color: #242424;
  p{
    padding: 0 15%;
    @include breakpoint(768px, 991px){
      text-align: center;
    }
  }
  p,b{
    margin: 0;
    color: $white;
  }
}

.brand-footer,
.brand-footer img{
  max-width: 100px;
}
@include breakpoint(0, 767px){
  .brand-footer{
    margin: 0 auto;
  }
  footer{
    ul.menu{
      li{
        display: block !important;
        margin: 0 !important;
        text-align: center;
      }
    }
  }
}