.contact__title{
  font-size: $contact-page-title;
  font-weight: $contact-page-weight;
  margin: 20px 0;
  @media ($mobile) {
    font-size: 35px;
  }
}

.contact__sidebar {
  *{
    display: block;
  }
}
.contact-sidebar__title{
  h3{
    font-size: 26px;
    font-weight: bold;
    margin: 0px;
  }
}
.contact-sidebar__address,
.contact-sidebar__phone,
.contact-sidebar__abn{
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  p{
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
  }
}

.contact-sidebar__socials{
  margin-top: 37px;
  .social-menu{
    a{
      display: inline-block;
      margin-right: 34px;
      &:last-of-type{
        margin-right: 0px;
      }
      &:hover{
        opacity: .7;
      }
    }
    .svg-img{
      width: 28px;
      height: 28px;
      path{
        fill: $red;
      }
    }
  }
}

.contactform__container{
  background-color: #eee;
}

.contact-top__section{
  padding-bottom: 74px;
  @media ($mobile) {
    padding-bottom: 30px;
  }
}
.contact-bottom__section{
  padding-top: 42px;
  padding-bottom: 50px;
  @media ($mobile) {
    padding-top: 20px;
    padding-bottom: 15px;
  }
}

.mobile-contact__sidebar{
  @media ($mobile) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}