.onsale{
  display: none;
}
.qty-text{
  color: #a1a1a1;
  font-size: 20px;
  margin-right: 20px;
}
.single-product__container{
  padding:{
    top: 20px;
    bottom: 50px;
  }
  @media ($mobile) {
    padding-bottom: 20px;
  }
  .product-images{
    text-align: center;
    @media ($mobile) {
      margin-bottom: 20px;
    }
    .images{
      > a{
        display: block;
        background-image: url($img + 'enlarge-image.svg');
        background-repeat: no-repeat;
        background-size: 42px 42px;
        background-position: 90% 100%;
        @media ($mobile) {
          background-size: 30px 30px;
        }
        img{
          @media ($not-mobile) {
            padding: 0 150px;
          }
        }
      }
    }
    .woocommerce-main-image{
      margin-bottom: 25px;
      @media ($mobile) {
        padding: 0 20%;
      }
      img{
        max-width: 350px;
      }
    }
  }

  .product_title{
    font-size: 50px;
    font-weight: bold;
    line-height: 47px;
    margin: 0;
    @media ($mobile) {
      font-size: 30px;
    }
  }
  .subtitle{
    margin: 0;
    font-size: 30px;
    line-height: 47px;
    color: $grey;
    @media ($mobile) {
      font-size: 23px;
      line-height: 33px;
      margin-bottom: 20px;
    }
  }
}

.product-variations{
  .variations{
    margin: 0;
    padding: 0;
    list-style: none;
    label{
      display: block;
      font-size: 16px;
      font-weight: normal !important;
      color: $grey;
    }
  }
}

.variations_form.cart{
  @media ($mobile) {
    margin-top: 20px;
  }
}

.woocommerce-variation-price{
  font-size: 30px;
  font-weight: bold;
  color: red;
  .amount{
    font-size: 30px;
    font-weight: bold;
    color: red;
    span{
      color: red;
    }
  }
}

.woocommerce-variation-add-to-cart{
  margin-top: 15px;
  margin-bottom: 15px;
}
.woocommerce-variation-delivery-note{
  display: block;
  > span{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }
}

.product-images-slides{
  position: relative;
  padding: 0 25px;
  .slick-arrow{
    @include prefix(transform, translateY(-50%));
    padding: 0;
    -webkit-appearance: none;
    content: '';
    color: transparent;
    border: 0;
    background-color: transparent;
    background-size: 9px 14px;
    width: 25px;
    height: 25px;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url($img + 'arrow-grey.svg');
    position: absolute;
    top: 50%;
    left: 0;
    &.slick-prev{
      @include prefix(transform, translateY(-50%) rotate(180deg));
    }
    &.slick-next{
      left: auto;
      right: 0;
    }
  }
  .image-item{
    opacity: .5;
    &.active{
      opacity: 1;
    }
    span{
      width: 120px;
      height: 120px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      @media ($mobile) {
        width: 100%;
        height: 70px;
      }
    }
  }
}

.related-product__container{
  overflow: hidden;
  position: relative;
  .bg{
    position: absolute;
    background-color: #eee;
    left: 0;
    right: 0;
    top: 35px;
    height: 239px;
    z-index: -1;
    @media ($mobile) {
      top: 20px;
      height: 130px;
    }
  }

  h2{
    margin: 0;
    color: #343434;
    font:{
      weight: bold;
      size: 50px;
    }
    @media ($mobile) {
      font-size: 30px;
    }
  }
}

.single-product-newsletter{
  background-color: #eee;
}
.related-products{
  text-align: center;
  margin: 60px 0 50px !important;
  @media ($mobile) {
    margin: 20px 0 20px !important;
  }
  li{
    display: inline-block;
    @media ($mobile) {
      width: 50%;
      padding: 0 20px;
    }
    a{
      display: block;
      &:hover,&:focus{
        text-decoration: none;
      }
      span{
        display: block;
        font-size: 20px;
        color: #343434;
        &.img{
          margin-bottom: 40px;
          position: relative;
          // &::after{
          //   content: '';
          //   position: absolute;
          //   bottom: -23%;
          //   left: 0;
          //   right: 0;
          //   margin: 0 auto;
          //   width: 100%;
          //   height: 130px;
          //   z-index: -1;
          //   background-image: $cup-shadow-img;
          //   background-size: 100% 100%;
          //   background-position: center;
          //   max-width: 250px;
          // }
          img{
            max-height: 221px;
            display: inline-block;
          }
        }
        &.title{
          font-weight: bold;
        }
        &.subtitle{
          color: #a1a1a1;
        }
        &.price{
          color: #ff0000;
        }
      }
    }
  }
}

.text-content-first{
  p{
    margin-bottom: 5px;
  }
}
.read-more-text{
  color: red;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}